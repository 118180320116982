<!--
 * @Author: 智客云网络科技
 * @Date: 2021-10-25 14:16:28
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:06:29
 * @Description: 系统操作日志
 * @FilePath: \src\views\Frame\Console\SystemRecord.vue
-->
<template>
  <div class="vue-box">
    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
        >重载数据</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table class="data-table" :data="TableData" stripe border row-key="Aid">
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 日志等级 -->
      <el-table-column label="日志等级" align="center" width="130px">
        <template slot-scope="s">
          <el-tag
            v-if="s.row.level === 'DEBUG' || s.row.level === 'INFO'"
            effect="dark"
            type="info"
            size="small"
            >{{ s.row.level }}</el-tag
          >
          <el-tag
            v-else-if="s.row.level === 'NOTICE'"
            effect="dark"
            size="small"
            >{{ s.row.level }}</el-tag
          >
          <el-tag
            v-else-if="
              s.row.level === 'WARNING' ||
              s.row.level === 'ERROR' ||
              s.row.level === 'CRITICAL'
            "
            effect="dark"
            type="warning"
            size="small"
            >{{ s.row.level }}</el-tag
          >
          <el-tag
            v-else-if="s.row.level === 'ALERT' || s.row.level === 'EMERGENCY'"
            effect="dark"
            type="danger"
            size="small"
            >{{ s.row.level }}</el-tag
          >
          <el-tag v-else effect="dark" type="success" size="small">{{
            s.row.level
          }}</el-tag>
        </template>
      </el-table-column>

      <!-- 产生模块 -->
      <el-table-column label="产生模块" width="130px">
        <template slot-scope="s">
          <el-tag effect="dark" size="small">{{ s.row.model }}</el-tag>
        </template>
      </el-table-column>

      <!-- 产生时间 -->
      <el-table-column
        label="产生时间"
        prop="add_time"
        align="center"
        width="160px"
      >
      </el-table-column>

      <!-- 日志内容 -->
      <el-table-column label="日志内容" show-overflow-tooltip prop="msg">
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="m.pageNo"
        :page-size.sync="m.pageSize"
        :total="m.dataCount"
        :page-sizes="[25, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSystemLog } from "./api"; //页面专有接口
export default {
  name: "SystemRecord",
  data() {
    return {
      m: {
        pageNo: 1, //当前页
        pageSize: 25, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], //请求日志
    };
  },
  methods: {
    //载入数据
    _Load() {
      const params = {
        pageNo: this.m.pageNo,
        pageSize: this.m.pageSize,
      };
      getSystemLog(params)
        .then((res) => {
          this.TableData = res.data.data;
          this.m.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 105001) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },
    // 查看参数详情
    _Lockapilog(jsonStr) {
      let jsonStrs = JSON.stringify(JSON.parse(jsonStr), null, "\t");
      let html =
        `<div class="lock-api-log"><textarea rows="35" readonly>` +
        jsonStrs +
        `</textarea></div>`;
      //显示弹窗
      this.$alert(html, "日志详情", {
        dangerouslyUseHTMLString: true,
        customClass: "lock-api-log",
      });
    },
  },
  created() {
    this._Load();
  },
};
</script>

<style>
.api {
  color: #44f;
  font-weight: 700;
  margin-left: 3px;
}

.apilog {
  width: 240px;
  line-height: 1.4;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff2f4;
  color: #c7254e;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resp {
  color: green;
}

.resper {
  color: red;
}

.resplog {
  width: 480px;
  line-height: 1.4;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ecf5ff;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timelongl1 {
  color: rgb(0, 128, 28);
}

.timelongl3 {
  color: rgb(255, 166, 0);
}

.timelongl5 {
  color: rgb(196, 0, 0);
}

textarea {
  /*去除选中时的边框*/
  outline: none;
}

.lock-api-log {
  width: 800px;
}

.lock-api-log textarea {
  width: 96%;
  font-size: 14px;
  font-weight: 600;
  border: none;
  resize: none;
}

/*滚动条的宽度*/
.lock-api-log textarea::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
.lock-api-log textarea::-webkit-scrollbar-track {
  width: 6px;
  background-color: #f1f1f1;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

/*滚动条的设置*/
.lock-api-log textarea::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
</style>
